@import 'assets/styles/mixins';

.ant-drawer-header{
  padding: 20px 24px;
  background: #19345A;
  border-radius: 0;
  color: #fff;
  font-weight: bold;
}
.ant-drawer-title{
  color: #fff;
  font-weight: bold;
}
.ant-drawer-close{
  color: #fff;
}
