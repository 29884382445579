// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-1;
}
.ant-checkbox-wrapper{
  color: rgba(0, 0, 0, 0.65);
}
