// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MENU */

.ant-layout-sider-children {
  padding: 24px 0;
}

.main-section {
  .ant-menu-item,
  .ant-menu-submenu {
    margin: 12px 0 !important;
    .anticon {
      top: 0 !important;
      position: relative;
    }
    .navLink,
    .ant-menu-title-content {
      font-size: 12px;
      font-weight: 600;
      &.active {
        color: #ffffff;

        &::before {
          border-left: 4px solid #ffffff;
        }
      }
    }
  }
  &.isCollapsed {
    .sub-layout {
      margin-left: 80px;
      transition: all ease 0.3s;
    }
    .ant-menu-inline-collapsed {
      & > .ant-menu-item {
        .anticon {
          top: 3px !important;
        }
      }
    }
  }
}

.sidebarMenu_holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
  .menuHolder {
    &__head {
      background-color: #333;
      height: 40px;
    }
    &__body {
      flex-grow: 1;
    }
  }
}
