@import '../../assets/styles/scss/styleGuide/variables';

.c-notifiContainer {
  display: block;
  position: relative;
  text-align: center;
  margin: 60px 0;

  .ant-result {
    padding: 0;
  }

  &.container-type-empty {
    &.small {
      .notifi {
        &_icon {
          height: 76px;
          width: 76px !important;
        }
      }
    }
  }

  .notifi {
    &_icon {
      display: inline-block;
      height: 100px;
    }
    &_spinner {
      color: var(--primary-color);
      font-size: 34px;
      margin-bottom: 14px;
    }
    &_message {
      color: var(--fontColor-extra-light);
      display: block;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: normal;
    }
  }
  .ant-result {
    .ant-result-icon {
      margin-bottom: 12px;
    }
  }
  &.small {
    margin: 20px 0;
    .ant-result {
      padding: 0;
      .ant-result-icon {
        margin-bottom: 0;
      }
    }
    .notifi {
      &_icon {
        height: 28px;
        width: 52px !important;
      }
      &_spinner {
        font-size: 24px;
        margin-bottom: 7px;
      }
      &_message {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}
