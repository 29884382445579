// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CARD */

.ant-card-body {
  margin-bottom: auto;
}
.ant-card-actions {
  background: transparent;
  border: none;
}
