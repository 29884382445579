.c-formElement {
  display: block;
  margin-bottom: 12px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    margin-bottom: 3px;
    padding-bottom: 0;
    label {
      font-size: 12px;
      line-height: 16px;
      height: auto;
    }
  }
  label {
    color: #161537;
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }
}
.center {
  text-align: center;
}

.customCard {
  .ant-card-head {
    padding: 0;
  }
  .ant-card-body {
    padding: 12px 0;
  }
}
