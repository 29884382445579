@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');

/* -------------------------------------
    Theme Variables
  ------------------------------------- */

$primary-color: #245ea4;
$secondary-color: #e8913b;
$border-color: #e9ecf7;
$page-bg-color: #f7f9fa;

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};

  --alert-success: #39a847;
  --alert-warning: #ffb300;
  --alert-fail: #ff6636;

  --white: #fff;
  --black: #000;

  --page-bg-color: #{$page-bg-color};
  --widget-bg-color: #ffffff;
  --border-color: #{$border-color};

  --disabled-color: #d6dce5;
  --table-th-background: #e9ecf7;
  --table-td-background: var(--white);

  --primary-font: 'Lato', sans-serif;
  --secondary-font: 'Nunito', sans-serif;
  --fontColor: #252d36;
  --fontColor-dark: #302e34;
  --fontColor-light: #4d5a74;
  --fontColor-extra-light: #b9b9b9;
  --placeholder-color: #898da9;
}

/* -------------------------------------
      SCSS Mixins
    ------------------------------------- */
@mixin rounded-corners($property) {
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  border-radius: $property !important;
}

@mixin box-shadow($property) {
  box-shadow: $property !important;
  -moz-box-shadow: $property !important;
  -webkit-box-shadow: $property !important;
  -ms-box-shadow: $property !important;
  -o-box-shadow: $property !important;
}

@mixin transition($property) {
  transition: $property;
  -moz-transition: $property;
  -webkit-transition: $property;
  -o-transition: $property;
  -ms-transition: $property;
}

@mixin transform($property) {
  transform: $property;
  -moz-transform: $property;
  -webkit-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
}

@mixin pulse-animation {
  animation: pulse-animation 2s infinite;
  @include transition(all ease 0.3s);
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(247, 168, 86, 0.5);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(230, 127, 51, 1);
      opacity: 0;
    }
  }
}

// --- Mixin for animate insight / recomm list
@mixin render-insight-list() {
  animation: animateCardList 0.25s ease forwards;
  $count: 0.1;
  @for $counter from 1 through 10 {
    &:nth-of-type(#{$counter}) {
      animation-delay: ($count + 0.07) + s;
    }
    $count: $count + 0.07;
  }
}
@keyframes animateCardList {
  0% {
    opacity: 0;
    left: 5%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}
