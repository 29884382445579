$colors: (
  primary: #005dff,
  primary-light: lighten(#005dff, 40%),
  primary-dark: darken(#005dff, 40%),
  accent: #fff6bb
);

@function color($color-name) {
  @return map-get($colors, $color-name);
}

html,
body {
  height: 100%;
}

body {
  //   background-color: color(primary);
  text-align: left;
  overflow-x: hidden !important;
}

// --- insight and recos
.c-insightContainer {
  font-family: var(--primary-font);
  padding: 16px 0;
  display: block;
  position: relative;
  .site-page-header {
    margin-bottom: 20px;
  }
  .ant-checkbox-group {
    width: 100%;
    .ant-list-item {
      border: 0 !important;
      padding: 0;
    }
  }
}

.infoList {
  display: block;
  margin-top: 10px;
  ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      vertical-align: middle;
      padding: 0 10px 0 0;
      border-right: 2px solid #eee;
      line-height: 16px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border: 0;
      }
      .dt {
        color: var(--fontColor-light);
        display: block;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        &.colored {
          color: #4fabff;
        }
        &.isPriority {
          text-transform: uppercase;
          color: var(--low);
          &.st-major {
            color: var(--major);
          }
          &.st-blocker {
            color: var(--critical);
          }
        }
      }
    }
  }
}

.infoTxt {
  display: block;
  font-size: 12px;
  font-weight: 600;
}
.readOnlyField {
  border: 0 !important;
  color: #999 !important;
  background-color: #fbfbfb !important;
  .ant-select-selector,
  .ant-select-selector,
  .ant-input-number {
    border: 0 !important;
    color: #999 !important;
    background-color: #fbfbfb !important;
  }
}

.text-right {
  text-align: right;
}
.text-left,
.c-dropdown {
  text-align: left;
}
.m0 {
  margin: 0 !important;
}
.p0 {
  padding: 0 !important;
}
.mt-3 {
  margin-top: 30px;
}

.c-spinnerContainer {
  display: block;
  position: relative;
  padding: 50px;
  text-align: center;
}

// :-moz-ui-invalid

.container-fluid {
  display: block;
  padding: 0 36px;
  margin: 0 auto;
  width: 100%;
  max-width: 1680px;
}
.c-pageWrapper {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 54px;
  .pageWrapper {
    &_header {
      background-color: #ffffff;
      border-bottom: 1px solid #eeeeee;
      box-sizing: border-box;
      position: fixed;
      top: 62px;
      left: 200px;
      height: 54px;
      padding: 10px 0;
      width: calc(100% - 200px);
      z-index: 12;
      transition: all ease 0.2s;
      box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.07);
    }
    &_body {
      position: relative;
      z-index: 11;
    }
  }
}

.isCollapsed {
  .c-pageWrapper {
    .pageWrapper {
      &_header {
        left: 80px;
        width: calc(100% - 80px);
      }
    }
  }
  .c-header {
    left: 80px;
    width: calc(100% - 80px);
  }
}
