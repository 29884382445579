@import '../../.././assets/styles/scss/styleGuide/variables';


.container {
  background-color: #081245;
  color: var(--white);
  height: auto;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    padding: 1rem;
    .heading {
      margin: auto;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.4rem;
      color: inherit;
      text-align: center;
    }
  }
  .logo {
    width: 100px;
  }
  .text {
    color: #b8c7e0;
    margin-bottom: 32px;
  }
  .campaign {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin: 32px;

    span {
      font-size: 1.4rem;
    }
  }
  .text-center {
    text-align: center;
  }

  .form-component {
    max-width: 800px;
    margin: auto;
    a {
      display: flex;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-channel-title {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--white);
        margin-bottom: 10px;
      }

      .ant-form-item {
        display: block;
        width: 400px;
      }

      .ant-row {
        display: block !important;
      }

      .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        background: none !important;
      }

      label {
        color: var(--white);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1rem;
        margin: auto;
      }

      label::before {
        content: none !important;
      }

      label::after {
        content: none;
      }

      .ant-select-selector,
      .ant-input {
        background: none;
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }
      .ant-select-arrow {
        color: inherit;
      }
      .ant-input {
        width: 300px;
        margin: auto;
        background-color: inherit;
      }
    }
  }

  .btn-next {
    background-color: #00dffe;
    border: none;
    width: 180px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    color: var(--white);
    font-weight: 600;
  }

  .color-white {
    color: var(--white);
  }

  .card {
    width: 600px;
    margin: auto;
    background-color: #2934fb;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 1rem 2rem;
    font-size: 1.2rem;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.predictedRevenue-modal {
  .card {
    width: 100%;
    margin: auto;
    background-color: #2934fb;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-modal-content {
    background: none;
  }
  .content {
    width: 250px;
    margin: auto;
    font-weight: 600;
    text-align: center;
    span {
      display: block;
      font-size: 2rem;
    }
  }
  .ant-modal-close {
    display: none;
  }
  .btn-next {
    background-color: #00dffe;
    border: none;
    color: var(--white);
    font-weight: 600;
  }

  .btn-ok {
    border: 1px solid #ffffff;
    background: none;
    color: #ffffff;
    width: 100px;
  }
}

.select-store-dropdown {
  background-color: #081245 !important;
  .ant-select-item {
    color: #ffffff;
  }
  .ant-select-item-option-active {
    color: #081245 !important;
  }
  .ant-select-item-option-selected {
    color: #081245 !important;
  }
}
