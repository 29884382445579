.main-sidebar {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  height: 100%;
}
.main-section {
  min-height: 100%;
}
.sub-layout {
  margin-left: 200px;
}

.sub-content {
  position: relative;
  margin-top: 62px;
}

.c-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  top: 0;
  left: 200px;
  padding: 0;
  height: 62px;
  width: calc(100% - 200px);
  z-index: 100;
  transition: all ease 0.2s;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.02);
}
.inviteButton {
  text-align: center;
  margin-left: 20px;
  font-weight: 600;
}
