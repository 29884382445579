// Override antd styles here
// actual antd styles are in less files in node_modules/antd
@import 'assets/styles/antd/style';
@import 'assets/styles/custom';
@import 'assets/styles/mixins';

//  GLOBAL
.page__title {
  font-size: 1.5em;
  padding: 10px 0;
  color: #000;
  display: inline-block;
}

.ant-card-hoverable.card-selected {
  box-shadow: 0 0 3px 1px lighten($primary, 20%);
  &:hover {
    box-shadow: 0 0 3px 1px lighten($primary, 20%);
  }
}

.form__label {
  font-weight: 600;
  display: inline;
}

.drawer__content {
  margin-bottom: 60px;
}

.drawer__bottom__wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.drawer__button {
  width: 120px;
  border-radius: 2px;
}

.inline-block {
  display: inline-block;
}

.date__picker {
  width: 250px;
}

.search__match {
  color: #f50;
}

.button__save {
  background: $success;
  border-color: $success;
  color: #fff;
  &:hover,
  &:focus {
    background: lighten($color: $success, $amount: 10%);
    color: #fff;
    border-color: $success;
  }
}
.button__cancel {
  background: #fff;
  border-color: $gray-6;
  color: $gray-6;
  &:hover,
  &:focus {
    background: #fff;
    color: #000;
    border-color: #000;
  }
}
.button__delete {
  &.ant-tooltip-disabled-compatible-wrapper {
    border: 0;
    padding: 0;
  }
}
.button__delete:not(.ant-tooltip-disabled-compatible-wrapper) {
  background: $danger;
  border-color: $danger;
  color: #fff;
  &:hover,
  &:focus {
    background: lighten($color: $danger, $amount: 10%);
    border-color: $danger;
    color: #fff;
  }
}

.topbar__dropdown__menu {
  margin-top: 15px;
}
.ask__aarya__button {
  margin-right: 20px;
}

.dropdown__icon {
  margin: auto;
  margin-top: 10px;
}

.card__wrapper {
  margin-bottom: 20px;
}

.sidebar__menu__icon {
  width: 25px;
}
.sidebar__hr {
  border: 1px solid #1d2f4a;
}

.login__main__icon__wrapper {
  display: block;
  padding: 20px;
  .logoContainer {
    display: block;
    margin-bottom: 4px;
    text-align: center;
    .login__main__icon {
      display: inline-block;
      max-height: 70px;
    }
  }
  .titleContainer {
    color: #245ea4;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }
}

.info__popup {
  font-size: 12px;
  font-weight: bold;
}

.info__popup__datasource {
  margin-bottom: 10px;
}
.ant-layout {
  background-color: #fff;
}
// LAYOUT
.content__container {
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.header__container {
  z-index: 9;
  &.ant-layout-header {
    border: none;
    box-shadow: 1px 0 5px 3px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
}
.layout__main__container {
  overflow: hidden;
}
.sidebar__container {
  position: relative;
  height: auto;
  display: flex;
  z-index: 9;
  flex-direction: column;
  flex-flow: column;
}
.edit__mode__background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #ededed;
}
.layout__main__container {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.display__root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

// TOPBAR
.topbar__wrapper {
  padding: 15px 20px;
}

.topbar__content__right {
  display: flex;
  flex-direction: row;
  float: right;
}

.topbar__account__wrapper {
  margin-left: -20px;
}
.topbar__account__select {
  max-width: 300px;
  width: 200px;
  color: #000;
  font-size: 1.17em;
  border-radius: 2px;
  border: 1px solid #eee;
  margin: 0 20px !important;
  .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection--single {
    .ant-select-selection__rendered {
      padding-right: 10px;
    }
  }
}
.topbar__search {
  width: auto;
  margin: 0 40px;
  box-shadow: 1px 0 5px 2px rgba(0, 0, 0, 0.1);
  .ant-input-group {
    & > .ant-input:first-child {
      border-radius: 2px;
      padding-right: 30px;
      border: none;
    }
    .ant-input-group-addon {
      position: absolute;
      right: 27px;
      top: 3px;
      .ant-input-search-button {
        border-radius: 2px;
        width: 25px;
        height: 25px;
        margin: auto;
        padding: 0;
        z-index: 9;
      }
    }
  }
}
.topbar__notification {
  margin: auto;
  margin-right: 30px;
  display: flex;
  .topbar__notification__icon {
    font-size: 1.3em;
    margin: 0;
    &.count {
      margin: 0 10px;
    }
  }
}
.topbar__text {
  margin: 0 10px;
  color: #000;
  font-size: 1.17em;
}
.topbar__greeting {
  margin: auto;
}

// DETAIL BAR
.detailbar__container {
  text-align: left;
  border-right: 1px solid #ccc;
  position: fixed;
  height: 100%;
  background: #f8f8f8;
  .toggle__wrapper {
    position: absolute;
    top: 20px;
    right: -13px;
    .ant-btn-sm {
      font-size: 12px;
    }
    .ant-btn:focus {
      color: rgba(0, 0, 0, 0.65);
      background: #fff;
      border-color: #d9d9d9;
    }
  }
}

.detailbar__menu {
  width: 256px;
  border: none;
  padding: 10px 20px 0;
  margin-top: 10px;
  .ant-menu-item-group-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    scrollbar-color: #f8f8f8 #f8f8f8;
  }
  .ant-menu {
    background: #f8f8f8;
  }
  .ant-menu-item.ant-menu-item-selected {
    a {
      color: $primary;
    }
    background: transparent;
    &:after {
      display: none;
    }
  }
}

.detailbar__button__wrapper {
  margin-top: 30px;
  text-align: center;
  button {
    width: 90%;
  }
}

//SIDEBAR
.sidebar__wrapper {
  height: 100%;
}
.sidebar__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .ant-menu-item {
    text-align: center;
  }
}
.sidebar__menu__top {
  margin-top: 2px;
}
.sidebar__menu__label {
  line-height: 1;
  font-size: 12px;
}
.sidebar__logo {
  text-align: center;
  padding: 10px 0;
  .logo {
    width: 35px;
  }
}

// COMPONENT LAYOUT (DASHBOARD/INTEGRATIONS PAGE)

.component__content__wrapper {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 52px);
  &.component__container {
    padding-left: 236px;
  }
}
.component__content__container {
  padding: 0 20px;
  margin-bottom: 40px;
}
.component__content {
  margin: 20px auto;
  max-width: 1400px;
  min-height: calc(100vh - 90px);
  width: 100%;
  justify-self: center;
}

// APP LAYOUT (SETTINGS/ASK_AARYA PAGE)
.app__content__wrapper {
  margin-bottom: 80px;
}

//DASHBOARD CREATE
.dashboard__create__wrapper {
  padding: 0 20px;
}
.dashboard__template__container {
  margin-bottom: 10px;
}
.dashboard__template__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  .ant-card-head-title {
    font-weight: bold;
    color: #555;
  }
  .dashboard__template__description {
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: inherit;
    height: 100%;
    line-height: 20px;
    display: -webkit-box;
    text-overflow: ellipsis;
  }
}
.dashboard__template__select__button__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.dashboard__template__select__button {
  padding: 0 3em;
}

.dashboard__template__select__button__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.dashboard__template__select__button {
  padding: 0 3em;
}
.data__sources__image__wrapper {
  text-align: center;
  .data__sources__image {
    width: 100%;
    margin-top: 30px;
  }
}
.data__sources__card__title {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  bottom: 90px;
}

// DASHBOARD VIEW
.dashboard__name__input.ant-input {
  font-size: 1.5em;
  border: none;
  border-radius: 0;
  padding: 0;
  background: #eee;
  &::selection {
    background: none;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.dashboard__view__wrapper {
  padding: 20px 20px;
}
.dashboard__header__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.dashboard__header {
  h2 {
    margin: 0;
  }
}
.dashboard__action {
  margin: 0 5px;
  button.ant-btn {
    width: 6.5em;
    border-radius: 2px;
    padding: 0;
  }
}
.dashboard__time__compare__wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: 0.8em;
  margin-top: 10px;
}
.dashboard__compare__wrapper {
  height: 100%;
  margin: auto 0;
  display: flex;
  margin-bottom: 0;
}
.dashboard__compare__checkbox {
  margin-top: 7px;
  span {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .ant-checkbox + span {
    display: inline-block;
    margin-top: 3px;
    margin-left: -3px;
  }
}
.dashboard__compare__picker {
  display: inline-block;
  width: 250px;
  margin-left: 10px;
}
.kpi__search__wrapper {
  .kpi__search {
    margin-bottom: 20px;
  }
  .kpi__tree {
    padding: 50px 40px;
  }
}

.kpi__drawer__button {
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
}

// WIDGET LAYOUT
.widget__container {
  &.react-grid-item {
    & > .react-resizable-handle {
      display: none;
    }
    &.editable {
      & > .react-resizable-handle {
        display: none;
      }
      .widget__edit__wrapper {
        position: relative;
        border: 2px solid #ddd;
      }
      .widget__actions {
        display: none;
      }
      .widget__options {
        display: none;
      }
      .widget__action__container__wrapper {
        display: block;
      }
    }
  }
}
.widget__card {
  height: 580px;
}
@media screen and (max-width: 1366px) {
  .widget__card {
    height: 560px;
  }
}
@media screen and (max-width: 1170px) {
  .widget__card {
    height: 480px;
  }
}
@media screen and (max-width: 1024px) {
  .widget__card {
    height: 480px;
  }
}
.widget__nodata {
  color: gray;
  margin-top: 4%;
  text-align: center;
}
.widget__loader {
  margin-left: 50%;
  margin-top: 40%;
}
.widget__header {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}
.widget__title__container {
  display: inline-block;
  max-width: calc(100% - 40px);
  .widget__title {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }
}
.widget__info {
  display: inline-block;
  padding: 0 10px;
  margin-top: 3px;
  width: 40px;
}
.widget__options {
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: 0.5s;
  &:hover {
    background: #f1f1f1;
    transition: 0.5s;
  }
}
.header__left {
  display: flex;
  align-items: center;
  min-width: calc(100% - 250px);
}
.header__right {
  display: flex;
  align-items: center;
}
.widget__actions {
  display: flex;
  align-items: center;
  .update__icon {
    font-size: 18px;
    margin: 0 3px;
  }
  .time__granularity__select {
    font-size: 15px;
    color: #000;
    width: 90px;
    .ant-select-selection {
      border: none;
      border-radius: 0;
      box-shadow: none;
      .ant-select-selection__rendered {
        margin-right: 5px;
      }
    }
  }
}

.widget__action__container__wrapper {
  position: absolute;
  display: none;
  top: 0;
  right: 0px;
  border-bottom-left-radius: 3px;
  background-color: #ddd;
  z-index: 9;
}
.widget__action__container {
  display: flex;
  flex-direction: row;
}
.widget__action {
  button {
    border: none;
    color: #000;
    &:hover {
      border: none;
      color: #fff;
    }
  }
  &.hidden {
    display: none;
  }
}

.widget__add__placeholder__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border: 1px dashed #ccc;
  border-radius: 3px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    background: #fafafa;
  }
  .widget__add__placeholder__text {
    font-size: 20px;
    font-weight: bold;
    color: #968f8f;
  }
}

// INTEGRATIONS
.integration__view__wrapper {
  padding: 0 20px;
}
.integration__status__wrapper {
  margin-left: 10px;
  display: inline-block;
  .integration__status {
    display: inline-block;
    position: relative;
    top: -3px;
    border-radius: 2px;
    width: 4.5em;
    text-align: center;
    color: #fff;
    font-weight: 600;
    &.active {
      background: $success;
    }
    &.inactive {
      background: $danger;
    }
  }
}
.integration__accounts__wrapper {
  .account__name__label {
    display: inline-block;
    font-weight: 600;
  }
  .account__name {
    display: inline-block;
  }
}
.visualisation__list__wrapper {
  margin-top: 20px;
}
.visualisation__list__title {
  font-size: 1.17em;
  font-weight: bold;
  margin: 10px 0;
}

.integration__form__account {
  margin-left: 20px;
}
.form__org__name {
  font-size: 14px;
  font-weight: 600;
}
.oauth__action__wrapper {
  text-align: center;
}
.oauth__connect__button {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  & > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 42px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &.fa-google {
      display: inline-block;
      background: transparent;
      line-height: 33px;
      & > img {
        width: 28px;
      }
      &::before {
        display: none;
      }
    }
  }
}

.button__facebook {
  color: #fff;
  background-color: #3b5998;
  &:hover {
    color: #fff;
    background: #3b5998;
    background-color: #3b5998;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.button__google {
  background: white;
  color: #444;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.form__orgs__wrapper {
  margin-bottom: 30px;
}

//AARYA PAGE

.aarya__container__wrapper {
  margin-top: 50px;
  padding: 10px;
}
.aarya__title {
  h1 {
    font-weight: 600;
    font-size: 1.7em;
  }
}
.aarya__input__wrapper {
  margin-bottom: 20px;
}
.tab__content__wrapper {
  background-color: #f5f5f5;
}
.tab__content {
  padding: 30px 20px;
  min-height: 400px;
}
.form__org__name {
  font-size: 14px;
  font-weight: 600;
}
.oauth__action__wrapper {
  text-align: center;
}
.oauth__connect__button {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  & > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 42px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &.fa-google {
      display: inline-block;
      background: transparent;
      line-height: 33px;
      & > img {
        width: 28px;
      }
      &::before {
        display: none;
      }
    }
  }
}

.button__facebook {
  color: #fff;
  background-color: #3b5998;
  &:hover {
    color: #fff;
    background: #3b5998;
    background-color: #3b5998;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.button__google {
  background: white;
  color: #444;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.form__orgs__wrapper {
  margin-bottom: 30px;
}

//AARYA PAGE

.ask__aarya__wrapper {
  text-align: center;
  background: #daeaf8;
  padding: 50px 0 30px;
}
.aarya__input__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.aarya__title {
  .ask__aarya__image {
    width: 80px;
    position: relative;
    top: -20px;
  }
  h1 {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 2em;
    color: #101010;
  }
}
.ask__aarya__input {
  max-width: 700px;
  width: 70vw;
  border-radius: 3px;
  box-shadow: 0 8px 20px 0 rgba(167, 171, 181, 0.94);
  background-color: #ffffff;
  .ant-input-group {
    & > .ant-input:first-child {
      border-radius: 3px;
      padding-right: 30px;
      height: 55px;
      border: none;
    }
    & > .ant-input:focus {
      box-shadow: none;
    }
    .ant-input-group-addon {
      .ant-input-search-button {
        border-radius: 3px;
        width: 100px;
        height: 45px;
        margin-right: 10px;
        padding: 0;
      }
    }
  }
}
.aarya__questions__wrapper {
  margin: 40px;
  max-width: 1200px;
  margin: 40px auto;
}
.insight__question__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.insight__question {
  font-weight: 600;
  font-size: 1.1em;
}
.insight__question__status {
  padding: 2px 7px;
  border-radius: 3px;
  // background: #fff;
  margin-left: 10px;
}
.insight__answer__wrapper {
  position: relative;
  min-height: 40px;
  margin: 0 25px;
  text-indent: 100px;
}
.download__report {
  margin-top: 30px;
  text-align: right;
}

//TEMP
.ant-tabs-bar {
  margin: 0 !important;
}

// Auth
.auth__page__wrapper {
  position: relative;
  min-height: 600px;
  height: 100vh;
  overflow: hidden;
  .auth__page__backgroud__wrapper {
    position: relative;
    width: 100%;
    min-height: 600px;
    height: 100vh;
    overflow: hidden;
  }
  .auth__page__backgroud {
    width: 100%;
    height: 100%;
  }
  .auth__page__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.auth__card__wrapper {
  width: 30vw;
  margin: 10vh auto;
  max-width: 600px;
  min-width: 400px;
}
.auth__form {
  &.ant-form-item {
    margin-bottom: 15px;
  }
}
.auth__form__button {
  width: 100%;
  padding: 20px 0;
  line-height: 0px;
  border-radius: 2px;
  margin-top: 20px;
}
.auth__error {
  display: block;
  text-align: center;
  color: $danger;
  font-size: 0.9em;
  margin-bottom: 10px;
}
.form__forgot {
  float: right;
}

// Animations

.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}

.detailbar-collapse-enter {
  transform: translateX(calc(20px - 100%));
}
.detailbar-collapse-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.detailbar-collapse-enter-done {
  transform: translateX(0);
}

.detailbar-collapse-exit {
  transform: translateX(0);
  // transition: transform 1s;
}

.detailbar-collapse-exit-active {
  transform: translateX(calc(20px - 100%));
  transition: transform 300ms;
}

.detailbar-collapse-exit-done {
  transform: translateX(calc(20px - 100%));
}

.component__content__wrapper.content-collapse-enter {
  padding-left: 0px;
}

.component__content__wrapper.content-collapse-enter-active {
  padding-left: 236px;
  transition: padding 300ms;
}

.component__content__wrapper.content-collapse-enter-done {
  padding-left: 236px;
}

.component__content__wrapper.content-collapse-exit {
  padding-left: 236px;
}

.component__content__wrapper.content-collapse-exit-active {
  padding-left: 0px;
  transition: padding 300ms;
}

.component__content__wrapper.content-collapse-exit-done {
  padding-left: 0px;
}

//ADMIN PANEL LISt
.ant-list-vertical .ant-list-item-action {
  float: right;
}

.ant-card-body {
  padding: 12px;
}

.recommendation__card__inner {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 100px;
}

.c-headerNav {
  display: block;
  position: relative;
  text-align: center;
  margin-top: 5px;
  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      position: relative;
      padding-right: 16px;
      margin-right: 16px;
      &::before {
        position: absolute;
        top: 2px;
        right: 0;
        height: 16px;
        width: 2px;
        content: '';
        background-color: #eee;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        &::before {
          display: none;
        }
      }
      .navLink {
        color: #ccc;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        &.active {
          color: var(--primary-color);
          position: relative;
          &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 100%;
            content: '';
            background-color: var(--primary-color);
          }
        }
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}
.showLessButton {
  background: transparent;
  border: 0;
  color: #158dfc;
  cursor: pointer;
}

.hidden_storeList {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

//ADMIN PANEL LISt
.rawData_percentValue {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding-right: 16px;
  position: relative;
  &.up {
    color: #299a29;
    .anticon {
      color: #299a29;
    }
  }
  &.down {
    color: #e43434;
    .anticon {
      color: #e43434;
    }
  }
  .anticon {
    position: absolute;
    top: 5px;
    right: -2px;
    font-size: 12px;
    font-weight: 600;
  }
}

.tableHeader {
  color: #333;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.mb-20 {
  margin-bottom: 20px;
}
.c-componentSection {
  display: block;
  margin-bottom: 24px;
  min-height: 200px;
  .componentHeading {
    background-color: rgb(234, 241, 249);
    display: block;
    position: relative;
    padding: 12px;
    margin-bottom: 12px;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    min-height: 48px;
    .ant-typography {
      margin-bottom: 0;
    }
  }
}
.block {
  display: block;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}

.color-alert {
  color: #d57171;
}
// ---- CSS for Dashboard card tables
.loaderContainer {
  display: block;
  padding: 25px;
  text-align: center;
}
.c-gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  &.grid_2by2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-dataTable {
  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .c-heading {
    display: block;
    font-size: 14px;
    line-height: 20px;
    &.sm {
      font-size: 12px;
      line-height: 16px;
    }
    &.bold {
      font-weight: 600;
    }
  }
  tfoot {
    .ant-table-cell {
      background-color: #fafafa;
      .card_count {
        font-size: 14px;
      }
    }
  }
}
.card_count {
  display: inline-block;
  color: var(--fontColor);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: relative;
  padding-right: 16px;
  .anticon {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 12px;
  }
}

#root,
.ant-layout.ant-layout-has-sider {
  height: 100%;
}
