.c-recommendationItem {
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  margin-bottom: 24px;
  padding: 16px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 4px #ececec;

  .recommTitle {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .recommDescription {
    display: block;
    font-size: 13px;
    color: #999;
    line-height: 18px;
    margin-bottom: 0;
  }
  .linkButton {
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    background-color: transparent;
    color: #158dfc;
    font-size: 12px;
    font-weight: 600;
  }
}
.expiringTag {
  background: #fff1f0;
  border: 1px solid #ffa39e;
  color: #cf1322;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  left: 16px;
  letter-spacing: 0.6px;
  padding: 2px 8px 1px;
  position: absolute;
  top: -8px;
  text-transform: uppercase;
  border-radius: 4px;
}
